import React from 'react';
import { Navigate, Outlet, } from 'react-router-dom';
import { checkToken } from '../utils/Common';

// handle the private routes
function PrivateRoute({ element, ...rest }) {
  // console.log("element ============ ");
  // console.log(element);
  // console.log("element ============   ");
  return checkToken() ?  element : <Navigate to="/" />;
}

export default PrivateRoute;