import { STATE_LOGIN } from './components/AuthForm';
import GAListener from './components/GAListener';
import { EmptyLayout } from './components/Layout';
import PageSpinner from './components/PageSpinner';
import PrivateRoute from './components/PrivateRoute';
import AuthPage from './pages/AuthPage';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { checkToken, setUserSession, removeUserSession } from './utils/Common';
import ResetPasswordPage from './pages/ResetPasswordPage';
import { connect } from 'react-redux';
import { loginUser } from './store/actions/login';
import { logoutUser } from './store/actions/login';
import './styles/styles.scss';

const PageNotFound = React.lazy(() => import('./pages/PageNotFound'));
const AdminPage = React.lazy(() => import('./pages/AdminPage'));
const Logout = React.lazy(() => import('./pages/LogoutPage'));

class App extends React.Component {
  render() {
    const isLogin = checkToken();

    if (isLogin) {
      if (isLogin && window.location.pathname !== '/signout') {
        let token_id = sessionStorage.getItem('token_id');
        let user_id = sessionStorage.getItem('user_id');
        let user_email = sessionStorage.getItem('useremail');
        let first_name = sessionStorage.getItem('first_name');
        let last_name = sessionStorage.getItem('last_name');
        let role_id = sessionStorage.getItem('role_id');
        let role = sessionStorage.getItem('role');

        let isDisplayPricePerm =sessionStorage.getItem('isDisplayPricePerm');
        let isDisplayStatusPerm =sessionStorage.getItem('isDisplayStatusPerm');
        let isEditOrderPerm =sessionStorage.getItem('isEditOrderPerm');
        isDisplayPricePerm = isDisplayPricePerm && isDisplayPricePerm == "true" ? true : false;
        isDisplayStatusPerm = isDisplayStatusPerm && isDisplayStatusPerm == "true" ? true : false;
        isEditOrderPerm = isEditOrderPerm && isEditOrderPerm == "true" ? true : false;
        

        let rememberMe = localStorage.getItem('rememberMe');

        this.props.loginUserHandle({
          user_id: user_id,
          email: user_email,
          role_id: role_id,
          role: role,
          isDisplayPricePerm,
          isDisplayStatusPerm,
          isEditOrderPerm,
        });
      } else {
        removeUserSession();
        this.props.logoutUserHandle();
      }
    }

    return (
      <BrowserRouter>
        {/* <GAListener> */}
          <React.Suspense fallback={<PageSpinner />}>
            <Routes>
              <Route
                path="/"
                element={<EmptyLayout><AuthPage authState={STATE_LOGIN} /></EmptyLayout>}
              />
              <Route
                path="/reset-password"
                element={ <EmptyLayout> <ResetPasswordPage /> </EmptyLayout> }
              />
              {/* <Route
                path="/"
                element={
                  <LayoutRoute
                    layout={EmptyLayout}
                    component={props => (
                      <AuthPage {...props} authState={STATE_LOGIN} />
                    )}
                  />
                }
              /> */}

              {/* <Route
                path="/reset-password"
                element={
                  <LayoutRoute
                    layout={EmptyLayout}
                    component={props => <ResetPasswordPage {...props} />}
                  />
                }
              />
              <Route
                path="/reset-password"
                element={
                  <LayoutRoute
                    layout={EmptyLayout}
                    component={props => <ResetPasswordPage {...props} />}
                  />
                }
              /> */}
              <Route path="/admin/*" element={<AdminPage />} />
              <Route path="/signout" element={<PrivateRoute element={Logout} />} />
              <Route path="/not-found" element={<PageNotFound />} />
              <Route path="*" element={<Navigate to="/not-found" />} />
            </Routes>
          </React.Suspense>
        {/* </GAListener> */}
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loginUserHandle: data => dispatch(loginUser(data)),
  logoutUserHandle: data => dispatch(logoutUser(data)),
});

export default connect(null, mapDispatchToProps)(App);