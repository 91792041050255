// return the user data from the session storage
export const GetUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}
 
// check the token from the session storage
export const checkToken = () => {

    //let isLogin = sessionStorage.getItem('isLogin');
    let isLogin = localStorage.getItem('isLogin');
    isLogin = ( isLogin === 'true' && sessionStorage.getItem('token_id') !== null ) ? true : false;

    return isLogin;
}
 
// remove the token and user from the session storage
export const removeUserSession = () => {
    sessionStorage.removeItem('token_id');
    sessionStorage.removeItem('user_id');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('useremail');
    sessionStorage.removeItem('first_name');
    sessionStorage.removeItem('last_name');
    sessionStorage.removeItem('role_id');
    sessionStorage.removeItem('isNotification');
    sessionStorage.setItem('isLogin', false);

    localStorage.setItem('isLogin', false);
    localStorage.setItem('isNotification', false);
}
 
// set the token and user from the session storage
export const setUserSession = (token_id, user_id, first_name, last_name, user_email, role_id, role, isRememberMe, isDisplayPricePerm, isDisplayStatusPerm, isEditOrderPerm) => {
    // console.log(" call setUserSession ")

    // console.log(token_id, user_id, first_name, last_name, user_email, role_id, isRememberMe);
    
    sessionStorage.setItem('token_id', token_id);
    sessionStorage.setItem('user_id', user_id);
    // sessionStorage.setItem('username', user_username);
    sessionStorage.setItem('useremail', user_email);
    sessionStorage.setItem('first_name', first_name);
    sessionStorage.setItem('last_name', last_name);
    sessionStorage.setItem('role_id', role_id);
    sessionStorage.setItem('role', role);
    sessionStorage.setItem('isLogin', true);
    sessionStorage.setItem('isNotification', true);
    
    sessionStorage.setItem('isDisplayPricePerm', isDisplayPricePerm);
    sessionStorage.setItem('isDisplayStatusPerm', isDisplayStatusPerm);
    sessionStorage.setItem('isEditOrderPerm', isEditOrderPerm);
    
    localStorage.setItem('isLogin', true);
    localStorage.setItem('isNotification', true);

    if( isRememberMe ) {
        // localStorage.setItem('rUsername', user_username );
        localStorage.setItem('rEmail', user_email );
        localStorage.removeItem('rPassword', null);
        localStorage.setItem('rememberMe', true);
    } else {
        localStorage.removeItem('rUsername', null);
        localStorage.removeItem('rEmail', null);
        localStorage.removeItem('rPassword', null);
        localStorage.removeItem('rememberMe', false);
    }
    return true;
}
   
export const isValidEmail = (email) => {
    //   var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
    
}


export const formatPhoneNumber = (phoneNumber) => {

    // console.log("phoneNumber ====== ")
    // console.log(phoneNumber)

    if(phoneNumber && phoneNumber !== undefined && phoneNumber !== "" ){
        
        const cleaned = phoneNumber.replace(/\D/g, '').slice(0,10);
        
        let formattedValue = cleaned;
        
        if(formattedValue && formattedValue !== ""){

            if (formattedValue.length > 3) 
            formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(3)}`;
            if (formattedValue.length > 7) 
            formattedValue = `${formattedValue.slice(0, 7)}-${formattedValue.slice(7)}`;
            return formattedValue;
            
        } else {
            
            return '';
        }
    } else {
        
        return '';
    }
    
};

export const getOrderStatusClassName = (str) => {
	if (!str || str == "") return "";

    if( str === "ORDER_RECEIVED"){
        return "order-received";
    }
    if( str === "WAITING_ON_PRESCRIPTION"){
        return "order-shipped";
    }
    if( str === "NEED_HELP_REQUIRED_PRESCRIPTION"){
        return "order-shipped";
    }
    if( str === "PRESCRIPTION_RECEIVED"){
        return "order-received";
    }
    if( str === "ORDER_APPROVED"){
        return "order-received";
    }
    if( str === "ORDER_SENT_TO_PHARMACY_YET_TO_SHIP"){
        return "sent-to-pharmacy";
    }
    if( str === "ORDER_SHIPPED"){
        return "order-shipped";
    }
    if( str === "ORDER_DELIVERED"){
        return "order-received";
    }
    if( str === "ORDER_REJECTED"){
        return "order-rejected";
    }
    if( str === "RX_IS_EXPIRED"){
        return "rx-expired";
    }
    if( str === "ORDER_ON_HOLD_SEE_NOTES"){
        return "order-on-hold";
    }
    if( str === "NO_REFILLS_AVAILABLE"){
        return "no-refills";
    }

    console.log(str);
    
    return "";

}

export const formatAmount = (num) => {
    if (num >= 1e9) {
        return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1e6) {
        return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1e3) {
        return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'k';
    }
    return num.toString();
}

export const allowOnlyNumber = function(e) {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) {
        e.preventDefault();
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }
};

export const allowOnlyNumberWithFloat = function (e) {
    const value = e.target.value;
    // Allow only digits and a single decimal point
    if (!/^\d*\.?\d*$/.test(value)) {
        e.preventDefault();
        // Replace invalid characters and ensure only one decimal point
        e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\./g, "$1");
    }
};