import ResetPasswordForm from '../components/ResetPasswordForm';
import PageSpinner from '../components/PageSpinner';
import React from 'react';
import { Card, Col, Row, Alert } from 'reactstrap';
import Axios from 'axios';
import { isValidEmail } from '../utils/Common';
import logo200Image from '../assets/img/logo/logo.png';

import {
	Button,
	Form,
	FormGroup,
	// InputGroup,
	// InputGroupAddon,
	// InputGroupText,
	Input,
	Label,
  } from 'reactstrap';
import { withRouter } from '../hocs/withRouter';
class ResetPasswordPage extends React.Component {
	// constructor(props) {
	// 	super(props);
		state = {
			email: '',
			password: '',
			confirmPassword: '',
			success: false,
			isLoading: true,
			error: '',
		};
	// }

	componentDidMount() {
		// const {
		// 	match: { params },
		// } = this.props;

		console.log("this.props =============================== ");
		console.log( this.props )
		
		const params = new URLSearchParams(this.props.location.search);
		console.log("params",params);
		const token = params.get("t");

		// console.log("token",token);

		setTimeout(() => {
			
			Axios
				.get(`/admin/reset-password-validate?t=${token}`)
				.then(res => {
					console.log(res);
					console.log(res.data);
					if(res.data.StatusCode == '0'){
						this.setState({ error: res.data.Message, isLoading: false  });
					} else {
						this.setState({ email: res.data.email, isLoading: false  });
					}
				})
				.catch(err => {
					console.log(err);
					this.setState({ error: 'Invalid or expired token' });
				});
		}, 1500);			
	}
	
	handleInputChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};
	// handleShowPassword = e => {
	// 	this.setState({ showPassword: !this.state.showPassword });
	// };

	handleLogoClick = () => {
		// this.props.history.push('/');
		this.props.navigate('/');
	};
	
	handleSubmit = e => {
		e.preventDefault();

		const { email, password, confirmPassword } = this.state;

		Axios.post('/api/reset-password', {
				email,
				password,
				confirmPassword,
			})
			.then(res => {
				this.setState({ success: true });
			})
			.catch(err => {
				console.log(err);
				this.setState({ error: 'Error resetting password' });
			});
	};

	// Login form submition handler ============================
	handleSubmitSetPassword = event => {
		event.preventDefault();
		this.setState({ usernameErr: null, passwordErr: null, cnfPasswordErr: null, loginErr: null });
	
		// if( this.state.username.length === 0 ) {
		//   this.setState({ usernameErr: 'Required!' });
		// } else if( this.state.username.length < 3 ) {
		//   this.setState({ usernameErr: 'Too Short!' });
		// } else if( this.state.username.length > 60 ) {
		//   this.setState({ usernameErr: 'Too Long!' });
		// }
		const { email, password, confirmPassword } = this.state;
	
		if (password.length === 0) {
			this.setState({ passwordErr: 'Required!' });
		} else if (password.length < 3) {
			this.setState({ passwordErr: 'Too Short!' });
		} else if (confirmPassword.length === 0) {
			this.setState({ cnfPasswordErr: 'Required!' });
		} else if (confirmPassword !== password) {
			this.setState({ cnfPasswordErr: 'Password and confirm password is not match.' });
		} else {
			let formData = {
				email,
				password
			}
			console.log("befor save reset password post", formData);
			Axios.post('admin/reset-password', formData)
				.then( async response => {
				//this.setState( { loading: false } );
		
				console.log( "reset-password response >>>>>>", response.data );
				
				if (response.data.StatusCode === '0') {
					this.setState({ loginErr: response.data.message });
				} else {
					var Result = await response.data;
					console.log( "reset-password success section>>>>>>>>>>>>>>>>>>>>>>>>>", Result );
		
					console.log("this.props.history",this.props.history);				
					// this.props.history.push('/');
					this.props.navigate('/');
					
				}
				})
				.catch(error => {
				console.log( "error", error);
				//this.setState( { loading: false } );
				});
		}
	};

	render() {

		const { email, password, confirmPassword, isLoading, error } = this.state;

		if (isLoading) {
			return <> <PageSpinner /><div style={{color: "var(--primary)"}} className='spinnerText'><p>Please wait while validating link...</p></div></>;
		}

		// if (success) {
		//   return <div>Password reset successfully</div>;
		// }
	
		
		return (
			<Row
				style={{
					height: '100vh',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Col md={6} lg={4}>
					<Card body>

						
						
						{/* <AuthForm authState={this.props.authState} onChangeAuthState={this.handleAuthState} onLogoClick={this.handleLogoClick} />  */}

						<Form onSubmit={this.handleSubmitSetPassword} >


							<div className="text-center pb-4">
								<img
									src={logo200Image}
									className="rounded"
									style={{ width: 250, height: 65, cursor: 'pointer' }}
									alt="logo"
									onClick={this.handleLogoClick}
								/>
							</div>

							{ (error) ? <Alert color="danger">{error}</Alert> : 
							
								<>
									<h5 className="text-center">Reset Password</h5>

									<FormGroup>
										<Label for="email">Email Address</Label>
										<Input
											name= 'email'
											type= 'email'
											placeholder= 'Enter your email address'
											// onChange={this.handleInputChange}
											value={email}
											disabled
											readOnly
										/>
										{this.state.emailErr ? (
										<p className="text-danger">{this.state.emailErr}</p>
										) : null}
									</FormGroup>

									<FormGroup>
										<Label for="password">Password</Label>
										<Input
											type={'password'}
											name="password"
											placeholder="Enter Password"
											onChange={e => this.handleInputChange(e)}
											// onKeyUp={e => this.handleKeyUp(e)}
											value={password}
											autoComplete="off"
										/>
										{this.state.passwordErr ? (
										<div className="text-danger">{this.state.passwordErr}</div>
										) : null}
									</FormGroup>

									<FormGroup>
										<Label for="confirmPassword"> Confirm Password </Label>
										<Input
											type={'password'}
											name="confirmPassword"
											placeholder="Re-enter Password"
											onChange={e => this.handleInputChange(e)}
											// onKeyUp={e => this.handleKeyUp(e)}
											value={confirmPassword}
											autoComplete="off"
										/>								
										{this.state.cnfPasswordErr ? (
										<div className="text-danger">{this.state.cnfPasswordErr}</div>
										) : null}
									</FormGroup>

									
									{this.state.loginErr ? (
										<p className="text-danger">{this.state.formError}</p>
									) : null}
									{
										<Button
										size="lg"
										className="border-0"
										block
										onClick={this.handleSubmitSetPassword}
										>
										Reset
										</Button>
									}
								</>
							}

						</Form> 
						
					</Card>
				</Col>
			</Row>
		);
	}
}

export default withRouter(ResetPasswordPage);
