import React from 'react';

const Dashboard = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
        <path d="M4.16667 8.33334H5.83333C7.5 8.33334 8.33333 7.5 8.33333 5.83334V4.16667C8.33333 2.50001 7.5 1.66667 5.83333 1.66667H4.16667C2.5 1.66667 1.66667 2.50001 1.66667 4.16667V5.83334C1.66667 7.5 2.5 8.33334 4.16667 8.33334Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.1667 8.33334H15.8333C17.5 8.33334 18.3333 7.5 18.3333 5.83334V4.16667C18.3333 2.50001 17.5 1.66667 15.8333 1.66667H14.1667C12.5 1.66667 11.6667 2.50001 11.6667 4.16667V5.83334C11.6667 7.5 12.5 8.33334 14.1667 8.33334Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.1667 18.3333H15.8333C17.5 18.3333 18.3333 17.5 18.3333 15.8333V14.1667C18.3333 12.5 17.5 11.6667 15.8333 11.6667H14.1667C12.5 11.6667 11.6667 12.5 11.6667 14.1667V15.8333C11.6667 17.5 12.5 18.3333 14.1667 18.3333Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.16667 18.3333H5.83333C7.5 18.3333 8.33333 17.5 8.33333 15.8333V14.1667C8.33333 12.5 7.5 11.6667 5.83333 11.6667H4.16667C2.5 11.6667 1.66667 12.5 1.66667 14.1667V15.8333C1.66667 17.5 2.5 18.3333 4.16667 18.3333Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Dashboard;