import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserSession } from "../utils/Common";
import {
	Button,
	Form,
	FormGroup,
	InputGroup,
	InputGroupText,
	Input,
	Label,
	Spinner,
} from "reactstrap";
import Axios from "axios";
import { loginUser } from "../store/actions/login";
import { logoutUser } from "../store/actions/login";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Logo from "../assets/img/logo/logo.png";
import { isValidEmail } from "../utils/Common";

const AuthForm = (props) => {
	const navigate = useNavigate();
	const [state, setState] = useState({
		username: localStorage.getItem("rUsername")
			? localStorage.getItem("rUsername")
			: "",
		email: localStorage.getItem("rEmail")
			? localStorage.getItem("rEmail")
			: "",
		password: localStorage.getItem("rPassword")
			? localStorage.getItem("rPassword")
			: "",
		showPassword: false,
		isRememberMe: false,
		usernameErr: null,
		passwordErr: null,
		loginErr: null,
		forgotSuccess: null,
		forgotErr: null,
		forgotPassword: false,
	});

	const [isBtnLoading, setIsBtnLoading] = useState(false);

	useEffect(() => {
		if (
			sessionStorage.getItem("token_id") !== null &&
			localStorage.getItem("isLogin") === "true"
		) {
			navigate("/admin/dashboard");
		}
		if (localStorage.getItem("rememberMe") === "true") {
			setState({ ...state, isRememberMe: true });
		}
	}, [navigate]);

	const changeAuthState = (authState) => (event) => {
		event.preventDefault();
		props.onChangeAuthState(authState);
	};

	const onInputchange = (event) => {
		console.log("onInputchange",state)
		setState({
			...state,
			[event.target.name]: event.target.value,
		});
	};

	const handleShowPassword = () => {
		console.log("handleShowPassword",state)
		setState({ ...state, showPassword: !state.showPassword });
	};

	const isRememberMeHandle = () => {
		console.log("isRememberMeHandle",state)
		setState({ ...state, isRememberMe: !state.isRememberMe });
	};

	const forgotPasswordHandle = () => {
		console.log("forgotPasswordHandle",state)
		setState({ ...state, forgotPassword: !state.forgotPassword });
	};

	const handleSubmit = (event) => { 
		event.preventDefault();
		setState({ ...state, usernameErr: null, passwordErr: null, loginErr: null });
		if (state.email.length === 0) {
			setState({ ...state, emailErr: "Required!" });
		} else if (!isValidEmail(state.email)) {
			setState({ ...state, emailErr: "Enter valid email!" });
		} else if (state.password.length === 0) {
			setState({ ...state, passwordErr: "Required!" });
		} else if (state.password.length < 3) {
			setState({ ...state, passwordErr: "Too Short!" });
		} else if (state.username.length > 15) {
			setState({ ...state, passwordErr: "Too Long!" });
		} else {
			setIsBtnLoading(true);
			let formData = {
				email: state.email,
				password: state.password,
			};
			Axios.post("admin/login", formData).then(async (response) => {
				if (response.data.StatusCode === "0") {
					setState({ ...state, loginErr: response.data.message });
					setIsBtnLoading(false);
				} else {
					
					var Result = await response.data;
					
					await setUserSession(
						Result.token,
						Result.user_id,
						Result.first_name,
						Result.last_name,
						Result.email,
						Result.role_id,
						Result.role,
						state.isRememberMe,
						Result.isDisplayPricePerm,
						Result.isDisplayStatusPerm,
						Result.isEditOrderPerm,
					);

					await props.loginUserHandle({
						user_id: Result.user_id,
						email: Result.email,
						role_id: Result.role_id,
						role: Result.role,
						isDisplayPricePerm: Result.isDisplayPricePerm,
						isDisplayStatusPerm: Result.isDisplayStatusPerm,
						isEditOrderPerm: Result.isEditOrderPerm,
					});
					navigate("/admin/dashboard");
				}
			}).catch((error) => {
				setIsBtnLoading(false);
				setState({ ...state, loginErr: "Something went wrong." });
			});
		}
		
	};

	// const forgotPasswordSubmitHandle = (event) => {
	// 	event.preventDefault();
	// 	setState({ ...state, emailErr: null, forgotErr: null, forgotSuccess: null });

	// 	if (state.email.length === 0) {
	// 		setState({ ...state, emailErr: "Required!" });
	// 	} else {
	// 		let formData = new FormData();
	// 		formData.append("email", state.email);

	// 		Axios.defaults.headers.post["TokenID"] =
	// 			sessionStorage.getItem("token_id");
	// 		Axios.post("forgot_password.php", formData).then((response) => {
	// 			if (response.data.StatusCode === "0") {
	// 				setState({ ...state, forgotErr: response.data.Message });
	// 			} else {
	// 				setState({ ...state, forgotSuccess: response.data.Message });
	// 			}
	// 		});
	// 	}
	// };

	// const handleKeyUp = (e) => {
	// 	e.preventDefault();
	// 	if (e.key === "Enter") {
	// 		setState({
	// 			...state,
	// 			usernameErr: null,
	// 			passwordErr: null,
	// 			loginErr: null,
	// 		});

	// 		if (state.username.length === 0) {
	// 			setState({ ...state, usernameErr: "Required!" });
	// 		} else if (state.username.length < 3) {
	// 			setState({ ...state, usernameErr: "Too Short!" });
	// 		} else if (state.username.length > 60) {
	// 			setState({ ...state, usernameErr: "Too Long!" });
	// 		} else if (state.password.length === 0) {
	// 			setState({ ...state, passwordErr: "Required!" });
	// 		} else if (state.password.length < 6) {
	// 			setState({ ...state, passwordErr: "Too Short!" });
	// 		} else if (state.username.length > 15) {
	// 			setState({ ...state, passwordErr: "Too Long!" });
	// 		} else {
	// 			let formData = new FormData();
	// 			formData.append("username", state.username);
	// 			formData.append("password", state.password);
	// 			Axios.post("login.php", formData)
	// 				.then((response) => {
	// 					if (response.data.StatusCode === "0") {
	// 						setState({ ...state, loginErr: response.data.Message });
	// 					} else {
	// 						var Result = response.data.Result[0];
	// 						setUserSession(
	// 							Result.token_id,
	// 							Result.user_id,
	// 							Result.user_username,
	// 							Result.role_id,
	// 							state.password,
	// 							state.isRememberMe,
	// 						);

	// 						props.loginUserHandle({
	// 							user_id: Result.user_id,
	// 							username: Result.user_username,
	// 							role_id: Result.role_id,
	// 							designation: Result.designation,
	// 							user_profile: Result.user_profile,
	// 						});

	// 						if (Result.role_id === "1") {
	// 							navigate("/admin/dashboard");
	// 						} else {
	// 							navigate("/user/dashboard");
	// 						}
	// 					}
	// 				})
	// 				.catch((error) => {
	// 					console.log("error", error);
	// 				});
	// 		}
	// 	}
	// };

	// const responseGoogle = (googleDetails) => {
	// 	setState({ ...state, loginErr: null });

	// 	let formData = new FormData();
	// 	formData.append("googleDetails", JSON.stringify(googleDetails));
	// 	Axios.post("google_login.php", formData).then((response) => {
	// 		if (response.data.StatusCode === "0") {
	// 			setState({ ...state, loginErr: response.data.Message });
	// 		} else {
	// 			var Result = response.data.Result[0];

	// 			setUserSession(
	// 				Result.token_id,
	// 				Result.user_id,
	// 				Result.user_username,
	// 				Result.role_id,
	// 				state.password,
	// 				state.isRememberMe,
	// 			);

	// 			props.loginUserHandle({
	// 				user_id: Result.user_id,
	// 				username: Result.user_username,
	// 				role_id: Result.role_id,
	// 				designation: Result.designation,
	// 				user_profile: Result.user_profile,
	// 			});

	// 			if (Result.role_id === "1") {
	// 				navigate("/admin/dashboard");
	// 			} else {
	// 				navigate("/user/dashboard");
	// 			}
	// 		}
	// 	});
	// };

	const {
		showLogo,
		usernameLabel,
		usernameInputProps,
		emailLabel,
		emailInputProps,
		passwordLabel,
		children,
		onLogoClick,
	} = props;
	const rememberMeChecked = state.isRememberMe
		? { defaultChecked: true }
		: null;

	return (
		<>
			<Form
				onSubmit={handleSubmit}
				className={state.forgotPassword ? "d-none" : ""}>
				{showLogo && (
					<div className='text-center pb-4'>
						<img
							src={Logo}
							style={{
								width: "auto",
								height: 45,
								cursor: "pointer",
								onClick: onLogoClick,
							}}
							alt='logo'
						/>
					</div>
				)}
				<h5 className='text-center'>Log In</h5>

				<FormGroup>
					<Label for={emailLabel}>{emailLabel}</Label>
					<Input
						{...emailInputProps}
						onChange={onInputchange}
						value={state.email}
					/>
					{state.emailErr ? (
						<p className='text-danger'>{state.emailErr}</p>
					) : null}
				</FormGroup>

				<FormGroup>
					<Label for={passwordLabel}>{passwordLabel}</Label>
					<InputGroup>
						<Input
							type={state.showPassword ? "text" : "password"}
							name='password'
							placeholder='Enter Password'
							onChange={onInputchange}
							// onKeyUp={handleKeyUp}
							value={state.password}
							autoComplete='off'
						/>
						<InputGroupText
							onClick={handleShowPassword}
							className='cursor-pointer'>
							{state.showPassword ? <BsEyeSlash /> : <BsEye />}
						</InputGroupText>
					</InputGroup>
					{state.passwordErr ? (
						<div className='text-danger'>{state.passwordErr}</div>
					) : null}
				</FormGroup>
				<FormGroup>
					<Input
						type='checkbox'
						name='rememberMe'
						id='rememberMe'
						onChange={isRememberMeHandle}
						value='1'
						{...rememberMeChecked}
					/>
					<Label className='ps-2' for='rememberMe'>
						Remember me
					</Label>
				</FormGroup>
				{state.loginErr ? (
					<p className='text-danger'>{state.loginErr}</p>
				) : null}

				<Button block type="submit"> 
					{ isBtnLoading ? <Spinner size="sm"> Loading... </Spinner> : null } <span> {' '}Login </span>
				</Button>
				
				{children}
			</Form>
		</>
	);
};

export const STATE_LOGIN = "LOGIN";
export const STATE_SIGNUP = "SIGNUP";

const mapStateToProps = (state) => ({
	userData: state,
});

const mapDispatchToProps = (dispatch) => ({
	loginUserHandle: (data) => dispatch(loginUser(data)),
	logoutUserHandle: (data) => dispatch(logoutUser(data)),
});

AuthForm.propTypes = {
	authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
	showLogo: PropTypes.bool,
	usernameLabel: PropTypes.string,
	usernameInputProps: PropTypes.object,
	emailLabel: PropTypes.string,
	emailInputProps: PropTypes.object,
	passwordLabel: PropTypes.string,
	passwordInputProps: PropTypes.object,
	confirmPasswordLabel: PropTypes.string,
	confirmPasswordInputProps: PropTypes.object,
	onLogoClick: PropTypes.func,
};

// AuthForm.defaultProps = {
// 	authState: "LOGIN",
// 	showLogo: true,
// 	usernameLabel: "Username",
// 	usernameInputProps: {
// 		name: "username",
// 		type: "text",
// 		placeholder: "Enter your username",
// 	},
// 	emailLabel: "Email",
// 	emailInputProps: {
// 		name: "email",
// 		type: "email",
// 		placeholder: "Enter your email address",
// 	},
// 	passwordLabel: "Password",
// 	passwordInputProps: {
// 		name: "password",
// 		type: "password",
// 		placeholder: "Enter your password",
// 	},
// 	confirmPasswordLabel: "Confirm Password",
// 	confirmPasswordInputProps: {
// 		type: "password",
// 		placeholder: "confirm your password",
// 	},
// 	onLogoClick: () => {},
// };

export default connect(mapStateToProps, mapDispatchToProps)(AuthForm);