import AuthForm, { STATE_LOGIN } from "../components/AuthForm";
import React from "react";
import { Card, Col, Row, CardBody } from "reactstrap";

class AuthPage extends React.Component {
	handleAuthState = (authState) => {
		if (authState === STATE_LOGIN) {
			this.props.history.push("/login");
		} else {
			this.props.history.push("/signup");
		}
	};

	handleLogoClick = () => {
		this.props.history.push("/");
	};
  authFormProps = {	authState: "LOGIN",
    showLogo: true,
    usernameLabel: "Username",
    usernameInputProps: {
      name: "username",
      type: "text",
      placeholder: "Enter your username",
    },
    emailLabel: "Email",
    emailInputProps: {
      name: "email",
      type: "email",
      placeholder: "Enter your email address",
    },
    passwordLabel: "Password",
    passwordInputProps: {
      name: "password",
      type: "password",
      placeholder: "Enter your password",
    },
    confirmPasswordLabel: "Confirm Password",
    confirmPasswordInputProps: {
      type: "password",
      placeholder: "confirm your password",
    },
    onLogoClick: () => {},}
	render() {
		return (
			<Row style={{ height: "100vh", justifyContent: "center", alignItems: "center" }}>
				<Col md={{ size: 4 }} sm='12'>
					<Card>
						<CardBody className='p-4'>
							<AuthForm
								authState={this.props.authState}
								onChangeAuthState={this.handleAuthState}
								onLogoClick={this.handleLogoClick}
                				{...this.authFormProps}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}

export default AuthPage;
