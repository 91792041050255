//import { GoogleLogin } from 'react-google-login';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import { withRouter } from '../hocs/withRouter';
import { setUserSession } from '../utils/Common';
import {
  Button,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Label,
} from 'reactstrap';

import Axios from 'axios';
import { loginUser } from '../store/actions/login';
import { logoutUser } from '../store/actions/login';

import { BsEye, BsEyeSlash } from 'react-icons/bs';
import logo200Image from '../assets/img/logo/logo.png';
import { isValidEmail } from '../utils/Common';

class AuthForm extends React.Component {
  componentDidMount() {
    console.log(">>>>>>>>>",sessionStorage.getItem('token_id'));
    
    if (
      sessionStorage.getItem('token_id') !== null 
      // && sessionStorage.getItem('role_id') !== null
    ) {
      // if (sessionStorage.getItem('token_id') ) {
        this.props.navigate('/admin/dashboard');
      // } 
    }
    if (localStorage.getItem('rememberMe') === 'true') {
      this.setState({ isRememberMe: true });
    }
  }
  state = {
    username: localStorage.getItem('rUsername') ? localStorage.getItem('rUsername') : '',
    email: localStorage.getItem('rEmail') ? localStorage.getItem('rEmail') : '',
    password: localStorage.getItem('rPassword')
      ? localStorage.getItem('rPassword')
      : '',
    showPassword: false,
    // email: '',
    isRememberMe: false,
    usernameErr: null,
    passwordErr: null,
    loginErr: null,
    forgotSuccess: null,
    forgotErr: null,
    forgotPassword: false,
  };
  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };
  onInputchange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleShowPassword = e => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  isRememberMeHandle = event => {
    this.setState({ isRememberMe: !this.state.isRememberMe });
  };
  forgotPasswordHandle = event => {
    this.setState({ forgotPassword: !this.state.forgotPassword });
  };

  // Login form submition handler ============================
  handleSubmit = event => {
    event.preventDefault();
    this.setState({ usernameErr: null, passwordErr: null, loginErr: null });

    // if( this.state.username.length === 0 ) {
    //   this.setState({ usernameErr: 'Required!' });
    // } else if( this.state.username.length < 3 ) {
    //   this.setState({ usernameErr: 'Too Short!' });
    // } else if( this.state.username.length > 60 ) {
    //   this.setState({ usernameErr: 'Too Long!' });
    // }

    if (this.state.email.length === 0) {
      this.setState({ emailErr: 'Required!' });
    } else if (!isValidEmail(this.state.email)) {
      this.setState({ emailErr: 'Enter valid email!' });
    } else if (this.state.password.length === 0) {
      this.setState({ passwordErr: 'Required!' });
    } else if (this.state.password.length < 3) {
      this.setState({ passwordErr: 'Too Short!' });
    } else if (this.state.username.length > 15) {
      this.setState({ passwordErr: 'Too Long!' });
    } else {

      let formData = {
        email: this.state.email,
        password: this.state.password
      }

      Axios.post('admin/login', formData)
        .then( async response => {
          //this.setState( { loading: false } );

          console.log( "Login response >>>>>>", response.data );
          
          if (response.data.StatusCode === '0') {
            // alert(1);
            this.setState({ loginErr: response.data.message });
          } else {
            // alert(2);
            console.log( "login success section" );
            var Result = await response.data;
            console.log( "login success section>>>>>>>>>>>>>>>>>>>>>>>>>", Result );

            await setUserSession(
              Result.token,
              Result.user_id,
              Result.first_name,
              Result.last_name,
              // Result.user_username,
              Result.email,
              Result.role,
              this.state.isRememberMe,
            );

            await this.props.loginUserHandle({
              user_id: Result.user_id,
              // username: Result.user_username,
              email: Result.email,
              role_id: Result.role_id,
              // designation: Result.designation,
              // user_profile: Result.user_profile,
            });

            console.log("this.props.history",this.props.history);
            
            this.props.navigate('/admin/dashboard');
            
          }
        })
        .catch(error => {
          console.log( "error", error);
          //this.setState( { loading: false } );
        });
    }
  };
  forgotPasswordSubmitHandle = event => {
    event.preventDefault();
    this.setState({ emailErr: null, forgotErr: null, forgotSuccess: null });

    if (this.state.email.length === 0) {
      this.setState({ emailErr: 'Required!' });
    } else {
      let formData = new FormData();
      formData.append('email', this.state.email);

      Axios.defaults.headers.post['TokenID'] =
        sessionStorage.getItem('token_id');
      Axios.post('forgot_password.php', formData).then(response => {
        if (response.data.StatusCode === '0') {
          this.setState({ forgotErr: response.data.Message });
        } else {
          this.setState({ forgotSuccess: response.data.Message });
        }
      });
    }
  };
  handleKeyUp(e) {
    e.preventDefault();
    if (e.key === 'Enter') {
      this.setState({ usernameErr: null, passwordErr: null, loginErr: null });

      if (this.state.username.length === 0) {
        this.setState({ usernameErr: 'Required!' });
      } else if (this.state.username.length < 3) {
        this.setState({ usernameErr: 'Too Short!' });
      } else if (this.state.username.length > 60) {
        this.setState({ usernameErr: 'Too Long!' });
      } else if (this.state.password.length === 0) {
        this.setState({ passwordErr: 'Required!' });
      } else if (this.state.password.length < 6) {
        this.setState({ passwordErr: 'Too Short!' });
      } else if (this.state.username.length > 15) {
        this.setState({ passwordErr: 'Too Long!' });
      } else {
        let formData = new FormData();
        formData.append('username', this.state.username);
        formData.append('password', this.state.password);
        Axios.post('login.php', formData)
          .then(response => {
            //this.setState( { loading: false } );
            if (response.data.StatusCode === '0') {
              this.setState({ loginErr: response.data.Message });
            } else {
              var Result = response.data.Result[0];
              setUserSession(
                Result.token_id,
                Result.user_id,
                Result.user_username,
                Result.role_id,
                this.state.password,
                this.state.isRememberMe,
              );

              this.props.loginUserHandle({
                user_id: Result.user_id,
                username: Result.user_username,
                role_id: Result.role_id,
                designation: Result.designation,
                user_profile: Result.user_profile,
              });

              if (Result.role_id === '1') {
                this.props.navigate('/admin/dashboard');
              } else {
                this.props.navigate('/user/dashboard');
              }
            }
          })
          .catch(error => {
            //this.setState( { loading: false } );
          });
      }
    }
  }
  responseGoogle = googleDetails => {
    this.setState({ loginErr: null });

    let formData = new FormData();
    formData.append('googleDetails', JSON.stringify(googleDetails));
    Axios.post('google_login.php', formData).then(response => {
      if (response.data.StatusCode === '0') {
        this.setState({ loginErr: response.data.Message });
      } else {
        var Result = response.data.Result[0];

        setUserSession(
          Result.token_id,
          Result.user_id,
          Result.user_username,
          Result.role_id,
          this.state.password,
          this.state.isRememberMe,
        );

        this.props.loginUserHandle({
          user_id: Result.user_id,
          username: Result.user_username,
          role_id: Result.role_id,
          designation: Result.designation,
          user_profile: Result.user_profile,
        });

        if (Result.role_id === '1') {
          this.props.navigate('/admin/dashboard');
        } else {
          this.props.navigate('/user/dashboard');
        }
      }
    });
  };
  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      emailLabel,
      emailInputProps,
      passwordLabel,
      children,
      onLogoClick,
    } = this.props;

    const rememberMeChecked = this.state.isRememberMe
      ? { defaultChecked: true }
      : null;

    return (
      <>
        {/*  Login Form ==========================================  */}

        <Form
          onSubmit={this.handleSubmit}
          className={this.state.forgotPassword ? 'd-none' : ''}
        >
          {showLogo && (
            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: 250, height: 65, cursor: 'pointer' }}
                alt="logo"
                onClick={onLogoClick}
              />
            </div>
          )}
          <h5 className="text-center">Log In</h5>
          {/* <FormGroup>
            <Label for={usernameLabel}>{usernameLabel}</Label>
            <Input {...usernameInputProps} onChange={this.onInputchange} value={this.state.username} />
            {(this.state.usernameErr) ? <p className="text-danger">{this.state.usernameErr}</p> : null}
          </FormGroup> */}

          <FormGroup>
            <Label for={emailLabel}>{emailLabel}</Label>
            <Input
              {...emailInputProps}
              onChange={this.onInputchange}
              value={this.state.email}
            />
            {this.state.emailErr ? (
              <p className="text-danger">{this.state.emailErr}</p>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Label for={passwordLabel}>{passwordLabel}</Label>
            <InputGroup>
              <Input
                type={this.state.showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Enter Password"
                onChange={e => this.onInputchange(e)}
                onKeyUp={e => this.handleKeyUp(e)}
                value={this.state.password}
                autoComplete="off"
              />
              <Button addonType="append">
                <InputGroupText
                  onClick={e => this.handleShowPassword(e)}
                  className="cursor-pointer"
                >
                  {this.state.showPassword ? <BsEyeSlash /> : <BsEye />}
                </InputGroupText>
              </Button>
            </InputGroup>
            {this.state.passwordErr ? (
              <div className="text-danger">{this.state.passwordErr}</div>
            ) : null}
          </FormGroup>
          <FormGroup className="d-flex align-items-center justify-content-between">
            <Label className="pl-4 cursor-pointer">
              <Input
                className="justify-content-start"
                type="checkbox"
                name="rememberMe"
                onChange={this.isRememberMeHandle}
                value="1"
                {...rememberMeChecked}
              />{' '}
              {'Remember me'}
            </Label>
            {/* <p className="text-muted cursor-pointer mb-0" onClick={(e) => this.forgotPasswordHandle(e)} >Forgot Password?</p> */}
          </FormGroup>
          {this.state.loginErr ? (
            <p className="text-danger">{this.state.loginErr}</p>
          ) : null}
          {
            <Button
              size="lg"
              className="border-0"
              block
              onClick={this.handleSubmit}
            >
              Login
            </Button>
            /*
          <h5 className="text-center mt-3">OR</h5>
          <GoogleLogin
          clientId="731503161828-p00qi64ijt9be8hudfhbkqte2gnbvf5m.apps.googleusercontent.com"
          buttonText="Login With Google"
          onSuccess={this.responseGoogle}
          onFailure={this.responseGoogle}
          cookiePolicy={'single_host_origin'} className="w-100 mt-2 google-btn" /> */
          }
          {children}
        </Form>

        {/*  Forgot Password Form ==========================================  */}

        <Form
          onSubmit={this.forgotPasswordSubmitHandle}
          className={this.state.forgotPassword ? '' : 'd-none'}
        >
          <div className="text-center pb-4">
            <img
              src={logo200Image}
              className="rounded"
              style={{ width: 250, height: 65, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
          <h5 className="text-center">Forgot Password</h5>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="text"
              name="email"
              placeholder="Enter Email"
              onChange={this.onInputchange}
              id="email"
            />
            {this.state.emailErr ? (
              <p className="text-danger">{this.state.emailErr}</p>
            ) : null}
          </FormGroup>
          <FormGroup className="d-flex justify-content-end">
            <p
              className="text-muted cursor-pointer"
              onClick={e => this.forgotPasswordHandle(e)}
            >
              Login
            </p>
          </FormGroup>
          <FormGroup>
            {this.state.forgotErr ? (
              <p className="text-danger">{this.state.forgotErr}</p>
            ) : null}
            {this.state.forgotSuccess ? (
              <p className="text-success">{this.state.forgotSuccess}</p>
            ) : null}

            <Button size="lg" className="border-0" block>
              Submit
            </Button>
          </FormGroup>
        </Form>
      </>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

const mapStateToProps = state => ({
  userData: state,
});
const mapDispatchToProps = dispatch => ({
  loginUserHandle: data => dispatch(loginUser(data)),
  logoutUserHandle: data => dispatch(logoutUser(data)),
});

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    name: 'username',
    type: 'text',
    placeholder: 'Enter your username',
  },
  emailLabel: 'Email',
  emailInputProps: {
    name: 'email',
    type: 'email',
    placeholder: 'Enter your email address',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    name: 'password',
    type: 'password',
    placeholder: 'Enter your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

//export default AuthForm;
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthForm),
);
