import React from 'react';

const Arrow = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
     <path d="M2.03996 4.455L5.29996 7.715C5.68496 8.1 6.31496 8.1 6.69996 7.715L9.95996 4.455" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Arrow;