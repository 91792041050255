import React, { useState, useEffect, useRef } from "react";
import {
	Nav,
	Navbar,
	NavItem,
	NavLink as BsNavLink,
	NavbarBrand,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Badge,
} from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Logo from "../../assets/img/logo/logo.png";
import DashboardIcon from "../../components/icons/dashboard";
import PatientsIcon from "../../components/icons/patients";
import PharmacyIcon from "../../components/icons/pharmacy";
import OrdersIcon from "../../components/icons/orders";
import InquiryIcon from "../../components/icons/inquiry";
import ReportsIcon from "../../components/icons/reports";
import SettingsIcon from "../../components/icons/settings";
import ArrowIcon from "../../components/icons/arrow";
import LogoutIcon from "../../components/icons/logout";
import bn from "../../utils/bemnames";
import { removeUserSession, checkToken } from "../../utils/Common";
import Axios from "axios";
import { logoutUser } from "../../store/actions/login";
import { menuAccess } from "../../store/actions/menuAccess";
import { setUserMenuAccess } from "../../store/actions/setUserMenuAccess";
import { withRouter } from "../../hocs/withRouter";

const bem = bn.create("sidebar");

const Sidebar = (props) => {
	const location = useLocation();
	const sidebarRef = useRef(null);
	const [isPatientsOpen, setPatientsOpen] = useState(
		window.location.pathname.startsWith("/admin/patients"),
	);
	const [isPharmacyOpen, setPharmacyOpen] = useState(
		window.location.pathname.startsWith("/admin/pharmacy"),
	);
	const [isOrderOpen, setOrderOpen] = useState(
		window.location.pathname.startsWith("/admin/order"),
	);
	const [isSettingOpen, setSettingOpen] = useState(
		window.location.pathname.startsWith("/admin/setting"),
	);
	const [count, setCount] = useState(0);
	const [legacyCount, setLegacyCount] = useState(0);

	const [getRole, setRole] = useState("");
	
	const [statusCount, setStatusCount] = useState({
		all: 0,
		active: 0,
		received: 0,
		waiting_on_rx: 0,
		rx_required: 0,
		rx_received: 0,
		approved: 0,
		pharmacy: 0,
		shipped: 0,
		delivered: 0,
		refills: 0,
		archive: 0,
	});

	useEffect(() => {
		if( props.loginData.role ){
			setRole(props.loginData.role);
		}

		if (sessionStorage.getItem("token_id") !== null) {
			Axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token_id");
			fetchMenuList();
			fetchTriageOrdersCount();
		}

		var intervalId = setInterval(() => {
			fetchTriageOrdersCount();
		}, 1000 * 120);


		const handleClickOutside = (event) => {
			if (
				sidebarRef.current &&
				!sidebarRef.current.contains(event.target)
			) {
				// Do nothing here if click outside
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			clearInterval( intervalId );
			
		};
	}, []);

	const fetchMenuList = () => {
		Axios.get("menu-list/new")
			.then((response) => {
				if (response.data.StatusCode === "0") {
					// handle no menu data
				} else if (response.data.StatusCode === "10") {
					removeUserSession();
					props.logoutUserHandle();
					props.navigate("/");
				} else {
					const data = response.data.Result[0].data;
					props.menuAccessHandle({ menuData: data });

					const currentUrl = window.location.pathname.replace(
						"/uss_hr",
						"",
					);
					const setMenuData = props.menuData.filter(
						(menu) => menu.menu_page === currentUrl,
					);
					props.setUserMenuAccessHandle({ setMenuData: setMenuData });
				}
			})
			.catch((error) => {
				// handle error
			});
	};

	const fetchTriageOrdersCount = () => {
		Axios.get("triage-orders/count")
			.then((response) => {
				if (response.data.StatusCode === "0") {
					// handle no data
				} else if (response.data.StatusCode === "10") {
					removeUserSession();
					props.logoutUserHandle();
					props.navigate("/");
				} else {
					const data = response.data;
					const statusCount = response.data.StatusCount;
					setCount(data.count);
					setLegacyCount(data.legacyCount);
					setStatusCount({
						all: statusCount.ALL,
						active: statusCount.ORDER_ACTIVE,
						received: statusCount.ORDER_RECEIVED,
						waiting_on_rx: statusCount.WAITING_ON_PRESCRIPTION,
						rx_required: statusCount.NEED_HELP_REQUIRED_PRESCRIPTION,
						rx_received: statusCount.PRESCRIPTION_RECEIVED,
						approved: statusCount.ORDER_APPROVED,
						pharmacy: statusCount.ORDER_SENT_TO_PHARMACY_YET_TO_SHIP,
						shipped: statusCount.ORDER_SHIPPED,
						delivered: statusCount.ORDER_DELIVERED,
						refills: 0,
						archive: statusCount.ORDER_ARCHIVED,
					})
				}
			})
			.catch((error) => {
				// handle error
			});
	};

	const toggleSubMenu = (menuName, event) => {
		const isInNav = event.target.closest("nav") !== null;
		if (isInNav)
			switch (menuName) {
				case "isPatientsOpen":
					setPatientsOpen(!isPatientsOpen);
					// setPatientsOpen(location.pathname.startsWith("/admin/patients") && isPatientsOpen ? true: false);
					break;
				case "isPharmacyOpen":
					setPharmacyOpen(!isPharmacyOpen);
					// setPharmacyOpen(location.pathname.startsWith("/admin/pharmacy") && isPharmacyOpen ? true: false);
					break;
				case "isOrderOpen":
					setOrderOpen(!isOrderOpen);
					// setOrderOpen(location.pathname.startsWith("/admin/orders") && isOrderOpen ? true: false);
					break;
				case "isSettingOpen":
					setSettingOpen(!isSettingOpen);
					// setSettingOpen( location.pathname.startsWith("/admin/settings") && isSettingOpen ? true: false );
					break;
				default:
					break;
			}
	};

	const getActiveClassName = (path) => {

		// console.log( " \n\n\n\n\n location.pathname ======================== " );
		// console.log( location.pathname );
		
		// console.log(" path ======================== ");
		// console.log( path );

		// console.log(" location.pathname === path ==========  ", location.pathname === path );
		
		return location.pathname === path ? "active" : "";
	}

	return (
		<aside
			className={
				bem.b() + " cr-sidebar--open border-end bg-white text-dark"
			}
			ref={sidebarRef}>
			<div className={bem.e("content")}>
				<Navbar container={false} className='border-0'>
					<NavbarBrand href='/'>
						<img alt='logo' src={Logo} width='auto' height='48' />
					</NavbarBrand>
					<Nav navbar className='nav-height border-top'>
						<NavItem>
							<BsNavLink
								to='/admin/dashboard'
								tag={NavLink}
								className={getActiveClassName( "/admin/dashboard" )}
							>
								<DashboardIcon />
								<span className='ps-3'>Dashboard</span>
							</BsNavLink>
						</NavItem>
						
						
						
						{getRole !== "Pharmacy" ? 
						<NavItem>
							<Dropdown
								inNavbar
								isOpen={isPatientsOpen}
								toggle={(event) => {
									toggleSubMenu("isPatientsOpen", event);
								}}>
								<DropdownToggle
									caret
									className='nav-link'
									tag={BsNavLink}>
									<PatientsIcon />
									<span className='ps-3 flex-fill'>
										Patients
									</span>
									<ArrowIcon />
								</DropdownToggle>
								<DropdownMenu>
									{getRole === "Admin" ? 
										<DropdownItem
											toggle={false}
											to='/admin/patients/add'
											className={getActiveClassName( "/admin/patients/add" )}
											tag={NavLink}
										>
											<span>Add Patients</span>
										</DropdownItem>
									: null }
									
									<DropdownItem
										toggle={false}
										to='/admin/patients/list'
										className={getActiveClassName( "/admin/patients/list" )}
										tag={NavLink}>
										<span>Patient List</span>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</NavItem> : null }
						
						
						
						
						<NavItem>
							<Dropdown
								inNavbar
								isOpen={isOrderOpen}
								toggle={(event) => { toggleSubMenu("isOrderOpen", event); }}
							>

									
									
								<DropdownToggle
									caret
									className='nav-link'
									tag={BsNavLink}>
									<OrdersIcon />
									<span className='ps-3 flex-fill'>
										Orders
									</span>
									<ArrowIcon />
								</DropdownToggle>
								
								
								
								
								<DropdownMenu>
									
									{getRole === "Admin" || getRole === "Broker" ?
										<>
											<DropdownItem
												toggle={false}
												to='/admin/orders/create'
												className={getActiveClassName( "/admin/orders/create" )}
												tag={NavLink}>
												<span>Create Order</span>
											</DropdownItem>

											<DropdownItem
												toggle={false}
												to='/admin/orders/triage'
												className={getActiveClassName( "/admin/orders/triage" )}
												tag={NavLink}>
												<span>
													Triage Order ({count ? count : "0"})
												</span>
											</DropdownItem> 
										</> : null 
									}

									{/* <DropdownItem
										toggle={false}
										to='/admin/orders/active'
										className={getActiveClassName( "/admin/orders/active" )}
										tag={NavLink}>
										<span>Active Order ({statusCount.active ? statusCount.active : "0"})</span>
									</DropdownItem> */}
									
									<DropdownItem
										toggle={false}
										to='/admin/orders/list'
										className={getActiveClassName( "/admin/orders/list" )}
										tag={NavLink}>
										<span>Order List ({statusCount.all ? statusCount.all : "0"})</span>
									</DropdownItem>
									
									{getRole === "Admin" ? 
										<DropdownItem
											toggle={false}
											to='/admin/orders/legacy'
											className={getActiveClassName( "/admin/orders/legacy" )}
											tag={NavLink}>
											<span>Legacy Orders ({legacyCount ? legacyCount : "0"})</span>
										</DropdownItem> : null 
									}

									{/* <DropdownItem
										toggle={false}
										to='/admin/orders/received'
										className={getActiveClassName( "/admin/orders/received" )}
										tag={NavLink}>
										<span>Order Received ({statusCount.received ? statusCount.received : "0"})</span>
									</DropdownItem>

									<DropdownItem
										toggle={false}
										to='/admin/orders/waiting-on-rx'
										className={getActiveClassName( "/admin/orders/waiting-on-rx" )}
										tag={NavLink}>
										<span>Waiting on Rx ({statusCount.waiting_on_rx ? statusCount.waiting_on_rx : "0"})</span>
									</DropdownItem>

									<DropdownItem
										toggle={false}
										to='/admin/orders/rx-required'
										className={getActiveClassName( "/admin/orders/rx-required" )}
										tag={NavLink}>
										<span>Rx Required ({statusCount.rx_required ? statusCount.rx_required : "0"})</span>
									</DropdownItem>

									<DropdownItem
										toggle={false}
										to='/admin/orders/rx-received'
										className={getActiveClassName( "/admin/orders/rx-received" )}
										tag={NavLink}>
										<span>Rx Received ({statusCount.rx_received ? statusCount.rx_received : "0"})</span>
									</DropdownItem>

									<DropdownItem
										toggle={false}
										to='/admin/orders/approved'
										className={getActiveClassName( "/admin/orders/approved" )}
										tag={NavLink}>
										<span>Approved ({statusCount.approved ? statusCount.approved : "0"})</span>
									</DropdownItem>

									<DropdownItem
										toggle={false}
										to='/admin/orders/pharmacy'
										className={getActiveClassName( "/admin/orders/pharmacy" )}
										tag={NavLink}>
										<span>Sent to Pharmacy ({statusCount.pharmacy ? statusCount.pharmacy : "0"})</span>
									</DropdownItem>

									<DropdownItem
										toggle={false}
										to='/admin/orders/shipped'
										className={getActiveClassName( "/admin/orders/shipped" )}
										tag={NavLink}>
										<span>Shipped ({statusCount.shipped ? statusCount.shipped : "0"})</span>
									</DropdownItem>

									<DropdownItem
										toggle={false}
										to='/admin/orders/delivered'
										className={getActiveClassName( "/admin/orders/delivered" )}
										tag={NavLink}>
										<span>Delivered ({statusCount.delivered ? statusCount.delivered : "0"})</span>
									</DropdownItem> */}
									
									<DropdownItem
										toggle={false}
										to='/admin/orders/refills'
										className={getActiveClassName( "/admin/orders/refills" )}
										tag={NavLink}>
										<span>Refills Due ({statusCount.refills ? statusCount.refills : "0"})</span>
									</DropdownItem>

									{ getRole === "Admin" ?
									<DropdownItem
										toggle={false}
										to='/admin/orders/archive'
										className={getActiveClassName( "/admin/orders/archive" )}
										tag={NavLink}>
										<span>Archived Orders ({statusCount.archive ? statusCount.archive : "0"})</span>
									</DropdownItem> : null }
									
									
								</DropdownMenu>
							</Dropdown>
						</NavItem>
						
						
						
						
						{getRole !== "Pharmacy" ? 

							<BsNavLink
								tag={NavLink}
								to='/admin/pharmacy/list'
								className={getActiveClassName( "/admin/pharmacy/list" )}
							>
								<PharmacyIcon />
								<span className='ps-3'>Pharmacy</span>
							</BsNavLink> 

						: null }




						{getRole === "Admin" ? <>
						
							<hr className='my-0' />

							<NavItem>
								<BsNavLink
									tag={NavLink}
									to='/admin/broker'
									className={getActiveClassName( "/admin/broker" )}
								>
									<ReportsIcon />
									<span className='ps-3'>Broker</span>
								</BsNavLink>
							</NavItem>

							<NavItem>
								<BsNavLink
									tag={NavLink}
									to='/admin/employer'
									className={getActiveClassName( "/admin/employer" )}
								>
									<ReportsIcon />
									<span className='ps-3'>Employer</span>
								</BsNavLink>
							</NavItem>

							<NavItem>
								<BsNavLink
									tag={NavLink}
									to='/admin/drugs'
									className={getActiveClassName( "/admin/drugs" )}
								>
									<ReportsIcon />
									<span className='ps-3'>Drugs</span>
								</BsNavLink>
							</NavItem>
							
							{/* backend user */}
							<NavItem>
								<BsNavLink
									tag={NavLink}
									to='/admin/users'
									className={getActiveClassName( "/admin/users" )}
								>
									<PatientsIcon />
									<span className='ps-3'>Backend Users</span>
								</BsNavLink>
							</NavItem>
							
							<NavItem>
								<BsNavLink
									tag={NavLink}
									to='/admin/reports'
									className={getActiveClassName( "/admin/reports" )}
								>
									<ReportsIcon />
									<span className='ps-3'>Reports</span>
								</BsNavLink>
							</NavItem>
							
							<NavItem>
								<BsNavLink
									tag={NavLink}
									to='/admin/inquiry'
									className={getActiveClassName( "/admin/inquiry" )}
								>
									<InquiryIcon />
									<span className='ps-3'>Inquiry</span>
								</BsNavLink>
							</NavItem>
							
							<NavItem>
								<Dropdown
									inNavbar
									isOpen={isSettingOpen}
									toggle={(event) => { toggleSubMenu("isSettingOpen", event); }}
								>
									<DropdownToggle
										caret
										className='nav-link'
										tag={BsNavLink}>
										<SettingsIcon />
										<span className='ps-3 flex-fill'>
											Settings
										</span>
										<ArrowIcon />
									</DropdownToggle>
									<DropdownMenu>
										{/* <DropdownItem
											toggle={false}
											to='/admin/users'
											className={getActiveClassName( "/admin/users" )}
											tag={NavLink}
											>
											<span>Users</span>
										</DropdownItem> */}
										<DropdownItem
											toggle={false}
											to='/admin/settings/order-status'
											className={getActiveClassName( "/admin/settings/order-status" )}
											tag={NavLink}
										>
											<span>Order Status</span>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</NavItem>

						</> : null }
						
						
						
					</Nav>
					<Nav navbar className='border-top'>
						<NavItem>
							<BsNavLink to='/sign-out' onClick={(e) => {
								e.preventDefault();
								removeUserSession();
								props.logoutUserHandle();
								props.navigate("/");
							}}>
								<Badge className='fs-5 p-0'>{ sessionStorage.getItem('first_name') ? sessionStorage.getItem('first_name')[0] : "A" } </Badge>
								<div className='ps-3 flex-fill nav-width'>
									
									<div className='d-flex justify-content-between'>
										<span className="text-ellipsis" >{sessionStorage.getItem('first_name') + " " + sessionStorage.getItem('last_name')}</span>
										<LogoutIcon />
									</div>

									<span className='text-gray small fw-normal user-overflow'> Logout </span>
									
								</div>
							</BsNavLink>
						</NavItem>
					</Nav>
				</Navbar>
			</div>
		</aside>
	);
};

const mapStateToProps = (state) => {
	return { 
		menuData: state.menuAccess.menuData,
		loginData : state.login,
	};
};
const mapDispatchToProps = (dispatch) => ({
	logoutUserHandle: (data) => dispatch(logoutUser(data)),
	menuAccessHandle: (data) => dispatch(menuAccess(data)),
	setUserMenuAccessHandle: (data) => dispatch(setUserMenuAccess(data)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Sidebar),
);
