import React from 'react';

const Patients = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
      <path d="M15.1167 18.0167C14.3833 18.2333 13.5167 18.3333 12.5 18.3333H7.5C6.48333 18.3333 5.61666 18.2333 4.88333 18.0167C5.06666 15.85 7.29166 14.1416 10 14.1416C12.7083 14.1416 14.9333 15.85 15.1167 18.0167Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 1.66667H7.5C3.33333 1.66667 1.66667 3.33334 1.66667 7.50001V12.5C1.66667 15.65 2.61667 17.375 4.88333 18.0167C5.06667 15.85 7.29167 14.1416 10 14.1416C12.7083 14.1416 14.9333 15.85 15.1167 18.0167C17.3833 17.375 18.3333 15.65 18.3333 12.5V7.50001C18.3333 3.33334 16.6667 1.66667 12.5 1.66667ZM10 11.8083C8.35 11.8083 7.01667 10.4667 7.01667 8.81669C7.01667 7.16669 8.35 5.83334 10 5.83334C11.65 5.83334 12.9833 7.16669 12.9833 8.81669C12.9833 10.4667 11.65 11.8083 10 11.8083Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.9833 8.81668C12.9833 10.4667 11.65 11.8083 10 11.8083C8.35 11.8083 7.01667 10.4667 7.01667 8.81668C7.01667 7.16668 8.35 5.83333 10 5.83333C11.65 5.83333 12.9833 7.16668 12.9833 8.81668Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Patients;