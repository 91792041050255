import { Content, Header, Sidebar } from "../../components/Layout";
import React from "react";
import { Outlet } from 'react-router-dom'

import { MdImportantDevices } from "react-icons/md";

class MainLayout extends React.Component {
	static isSidebarOpen() {
		return document
			.querySelector(".cr-sidebar")
			.classList.contains("cr-sidebar--open");
	}

	componentWillReceiveProps({ breakpoint }) {
		if (breakpoint !== this.props.breakpoint) {
			this.checkBreakpoint(breakpoint);
		}
	}

	// close sidebar when
	handleContentClick = (event) => {
		// close sidebar if sidebar is open and screen size is less than `md`
		if (
			MainLayout.isSidebarOpen() &&
			(this.props.breakpoint === "xs" ||
				this.props.breakpoint === "sm" ||
				this.props.breakpoint === "md")
		) {
			this.openSidebar("close");
		}
	};

	checkBreakpoint(breakpoint) {
		switch (breakpoint) {
			case "xs":
			case "sm":
			case "md":
				return this.openSidebar("close");

			case "lg":
			case "xl":
			default:
				return this.openSidebar("open");
		}
	}

	openSidebar(openOrClose) {
		if (openOrClose === "open") {
			return document
				.querySelector(".cr-sidebar")
				.classList.add("cr-sidebar--open");
		}
		if (openOrClose === "close") {
			document
				.querySelector(".cr-sidebar")
				.classList.remove("cr-sidebar--open");
		}
	}

	render() {
		const { children } = this.props;
		return (
			<main className='cr-app'>
				{sessionStorage.getItem('token_id') ? <Sidebar /> : null }
				
				<Content fluid onClick={this.handleContentClick}>
					{/* <Header /> */}
					{children}
				</Content>
			</main>
		);
	}
}

export default MainLayout;
