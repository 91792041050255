import React from 'react';

const Inquiry = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
      <path d="M2.17337 4.70687C1.66667 5.7798 1.66667 7.31171 1.66667 10C1.66667 13.1248 1.66667 14.6872 2.46243 15.7824C2.71943 16.1362 3.0305 16.4472 3.38423 16.7042C4.4795 17.5 6.04189 17.5 9.16667 17.5H10.8333C13.9581 17.5 15.5205 17.5 16.6158 16.7042C16.9695 16.4472 17.2806 16.1362 17.5376 15.7824C18.3333 14.6872 18.3333 13.1248 18.3333 10C18.3333 7.29271 18.3333 5.75821 17.8158 4.68419M2.17337 4.70687C2.25564 4.53266 2.35127 4.37055 2.46243 4.21756C2.71943 3.86383 3.0305 3.55276 3.38423 3.29576C4.4795 2.5 6.04189 2.5 9.16667 2.5H10.8333C13.9581 2.5 15.5205 2.5 16.6158 3.29576C16.9695 3.55276 17.2806 3.86383 17.5376 4.21756C17.6439 4.3639 17.736 4.51857 17.8158 4.68419M2.17337 4.70687L2.5 5L4.10744 6.60744C6.88522 9.38522 8.27411 10.7741 10 10.7741C11.7259 10.7741 13.1148 9.38522 15.8926 6.60745L17.5 5L17.8158 4.68419" stroke="currentColor" strokeWidth="1.5"/>
    </svg>
  )
}

export default Inquiry;