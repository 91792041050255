import React from 'react';

const LogOut = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
      <g clipPath="url(#clip0_166_4548)">
        <path d="M12.4984 14.1666C12.4883 15.9792 12.4079 16.9608 11.7676 17.6011C11.0354 18.3333 9.85685 18.3333 7.49983 18.3333L6.6665 18.3333C4.30948 18.3333 3.13096 18.3333 2.39873 17.6011C1.6665 16.8688 1.6665 15.6903 1.6665 13.3333L1.6665 6.66665C1.6665 4.30963 1.6665 3.13111 2.39873 2.39888C3.13096 1.66665 4.30948 1.66665 6.6665 1.66665L7.49983 1.66665C9.85685 1.66665 11.0354 1.66665 11.7676 2.39888C12.4079 3.0392 12.4883 4.02079 12.4984 5.83332" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M7.5 10L18.3333 10M18.3333 10L15.4167 12.5M18.3333 10L15.4167 7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_166_4548">
         <rect x="20" y="20" width="20" height="20" rx="5" transform="rotate(-180 20 20)" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    
  )
}

export default LogOut;