import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { createRoot } from 'react-dom/client';

import App from './App';
import Axios from 'axios';

// import reducer from './store/reducers/login';
import reducer from './store/reducers/index';
// import * as actionTypes from '../src/store/actions/loginActionTypes';
import { logoutUser } from '../src/store/actions/login';
import { removeUserSession } from '../src/utils/Common';


const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

// Local
// Axios.defaults.baseURL = 'http://localhost:3001/';

// /* Dev */
// Axios.defaults.baseURL = 'https://www.rxfree4me.com:3002/'; /* server testing port */

// /* Live */
Axios.defaults.baseURL = 'https://www.rxfree4me.com:3001/'; /* server testing port */

Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

Axios.defaults.headers.post['Accept'] = 'application/json';

if(sessionStorage.getItem("token_id") && sessionStorage.getItem("token_id") !== ""){
  Axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token_id");
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const { dispatch } = store;
Axios.interceptors.response.use(
  response => response,
  error => {
    console.log("axios.interceptors error>>>", error)
    // console.log("axios.interceptors error.response>>>",error.response)

    if (error?.response?.status === 401 &&
      error?.response?.statusText === "Unauthorized") {

      removeUserSession();
      dispatch(logoutUser());
      // alert("logout")
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
