import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, InputGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import Arrow from '../../components/icons/arrow'

class Header extends React.Component {
  render() {
    const headerTitle = this.props.headerTitle;
    return (
      <header className='p-3 border-bottom'>
        <Container fluid={true} className='g-0'>
          <Row className='align-items-center'>
            <Col xs="8">
              <h1 className='mb-0 h4'>{headerTitle}</h1>
            </Col>
            {/* <Col xs="4">
              <InputGroup className='search'>
                <ButtonDropdown
                  toggle={function noRefCheck() { }}
                >
                  <DropdownToggle caret color='light'>
                    <span className='pe-2'>Patients</span>
                    <Arrow />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      Patients
                    </DropdownItem>
                    <DropdownItem>
                      Pharmacy
                    </DropdownItem>
                    <DropdownItem>
                      Orders
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
                <Input placeholder="Search" />
              </InputGroup>
            </Col> */}
          </Row>
        </Container>
      </header>
    );
  }

}
export default Header;
Header.propTypes = {
  headerTitle: PropTypes.string.isRequired,
};

// import SearchInput from 'components/SearchInput';
// import { NavLink as NavCustom } from 'react-router-dom';
// import React from 'react';
// import { connect } from 'react-redux';

// import {
//   MdClearAll,
//   MdExitToApp,
//   MdPersonPin,
//   MdSettingsApplications
// } from 'react-icons/md';
// import {
//   Button,
//   ListGroup,
//   ListGroupItem,
//   Nav,
//   Navbar,
//   NavItem,
//   NavLink,
//   Popover,
//   PopoverBody,
// } from 'reactstrap';
// import bn from '../../utils/bemnames';

// const bem = bn.create('header');

// class Header extends React.Component {

//   state = {
//     isOpenNotificationPopover: false,
//     isNotificationConfirmed: false,
//     isOpenUserCardPopover: false,
//   };

//   componentDidMount() {

//     document.body.addEventListener('click', (flag) => this.toggleUserCardPopover(false));
//   }
//   UNSAFE_componentWillUnmount() {
//     document.body.removeEventListener('click', this.toggleUserCardPopover);
//   }

//   toggleNotificationPopover = () => {
//     this.setState({
//       isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
//     });

//     if (!this.state.isNotificationConfirmed) {
//       this.setState({ isNotificationConfirmed: true });
//     }
//   };

//   toggleUserCardPopover = (flag) => {

//     if (flag) {

//       this.setState({
//         isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
//       });
//     } else {
//       this.setState({
//         isOpenUserCardPopover: false,
//       });
//     }
//   };

//   handleSidebarControlButton = event => {
//     event.preventDefault();
//     event.stopPropagation();

//     document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
//   };

//   render() {

//     let ProfileUrl = "/user/profile";
//     let Settings = null;
//     if (sessionStorage.getItem('role_id') !== null) {

//       if (sessionStorage.getItem('role_id') === "1") {
//         ProfileUrl = "/admin/profile";
//         Settings = <NavCustom to="/admin/settings" >
//           <ListGroupItem tag="button" action className="border-light" >
//             <MdSettingsApplications />
//             <span className="pl-3">Settings</span>
//           </ListGroupItem>
//         </NavCustom>
//       }
//     }

//     return (<>

//       <Button className="menu-toggler" outline onClick={this.handleSidebarControlButton}>
//         <MdClearAll size={25} />
//       </Button>
//     </>


//     );
//   }
// }
// const mapStateToProps = state => ({
//   userData: state.login
// });
// export default connect(mapStateToProps, null)(Header)
