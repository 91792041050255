import React from 'react';

const Pharmacy = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
      <path d="M2.50833 9.35001V13.0917C2.50833 16.8333 4.00833 18.3333 7.75 18.3333H12.2417C15.9833 18.3333 17.4833 16.8333 17.4833 13.0917V9.35001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 9.99999C11.525 9.99999 12.65 8.75832 12.5 7.23332L11.95 1.66666H8.05833L7.5 7.23332C7.35 8.75832 8.475 9.99999 10 9.99999Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.2583 9.99999C16.9417 9.99999 18.175 8.63332 18.0083 6.95832L17.775 4.66666C17.475 2.49999 16.6417 1.66666 14.4583 1.66666H11.9167L12.5 7.50832C12.6417 8.88332 13.8833 9.99999 15.2583 9.99999Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.7 9.99999C6.075 9.99999 7.31667 8.88332 7.45 7.50832L7.63333 5.66666L8.03333 1.66666H5.49167C3.30833 1.66666 2.475 2.49999 2.175 4.66666L1.95 6.95832C1.78333 8.63332 3.01667 9.99999 4.7 9.99999Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 14.1667C8.60833 14.1667 7.91667 14.8583 7.91667 16.25V18.3333H12.0833V16.25C12.0833 14.8583 11.3917 14.1667 10 14.1667Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Pharmacy;